<template>
    <div>
        <v-scroll-x-reverse-transition>
            <v-card rounded="lg" v-if="registered">
                <v-card-text>
                    <v-alert
                        class="py-6"
                        type="success"
                        color="primary darken-1"
                        prominent
                        border="top"
                        outlined
                    >
                        <h4>
                            You have successfully registered for access to the
                            Pacific Screening online application portal.
                        </h4>
                    </v-alert>
                    <v-card-actions class="justify-center">
                        <v-btn color="primary darken-1" :href="login_url">
                            <v-icon class="mr-2">mdi-key</v-icon>Continue to
                            login
                        </v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-scroll-x-reverse-transition>
        <v-scroll-x-transition>
            <v-card rounded="lg" v-if="!registered">
                <v-card-text>
                    <psi-form-section
                        ref="form"
                        :value="user"
                        name="student-data"
                        :fields="fields"
                        color="accent"
                        @update:password="updatePassword"
                    ></psi-form-section>
                    <v-alert v-if="error" color="error" icon="mdi-alert">
                        {{ error }}
                    </v-alert>
                    <v-card-actions class="justify-end">
                        <v-btn
                            color="primary"
                            @click="register"
                            :loading="dataLoading"
                        >
                            <v-icon class="mr-2">mdi-account-plus</v-icon
                            >Register</v-btn
                        >
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-scroll-x-transition>
    </div>
</template>

<script>
export default {
    name: "register-user",
    props: {},
    data() {
        return {
            user: {},
            error: "",
            registered: false,
            dataLoading: false,
        };
    },
    computed: {
        login_url() {
            return this.$utils.loginUrl();
        },
        fields() {
            const idFields =
                this.user.registration_mode === "code"
                    ? [
                          {
                              type: "text",
                              name: "registration_code",
                              required: true,
                              key: "registration_code",
                              placeholder: "Enter registration code",
                              rules: [
                                  (v) =>
                                      v.length == 9 ||
                                      `Registration code must be 9 characters`,
                              ],
                              label: "Registration Code",
                              disabled: false,
                              icon: this.$config("icons.code"),
                          },
                      ]
                    : [
                          {
                              type: "text",
                              name: "email",
                              key: "email",
                              label: "Email",
                              disabled: true,
                              icon: this.$config("icons.email"),
                          },
                          {
                              type: "text",
                              format: "password",
                              required: true,
                              name: "password",
                              key: "password",
                              label: "Password",
                              validateOnBlur: false,
                              icon: this.$config("icons.password"),
                          },
                          {
                              type: "text",
                              format: "password",
                              required: true,
                              name: "password_confirm",
                              key: "password_confirm",
                              label: "Confirm Password",
                              validateOnBlur: false,
                              icon: this.$config("icons.password"),
                          },
                      ];
            return [
                {
                    type: "text",
                    required: true,
                    name: "name",
                    key: "name",
                    label: "Name",
                    icon: this.$config("icons.name"),
                },
            ].concat(idFields);
        },
    },
    methods: {
        async register() {
            if (this.$refs.form.validate()) {
                console.log("Registration is valid");
            } else {
                console.log("Registration is invalid");
            }
        },
        updatePassword(value) {
            console.log("Updated password with value = %s", value);
            const index = this.fields.findIndex(
                (field) => field.name === "password_confirm"
            );
            let field = this.fields[index];
            field.rules = [(v) => v === value || "Passwords do not match"];
            this.fields.splice(index, 1, field);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>