var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-scroll-x-reverse-transition",
        [
          _vm.registered
            ? _c(
                "v-card",
                { attrs: { rounded: "lg" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "py-6",
                          attrs: {
                            type: "success",
                            color: "primary darken-1",
                            prominent: "",
                            border: "top",
                            outlined: ""
                          }
                        },
                        [
                          _c("h4", [
                            _vm._v(
                              " You have successfully registered for access to the Pacific Screening online application portal. "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "justify-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary darken-1",
                                href: _vm.login_url
                              }
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("mdi-key")
                              ]),
                              _vm._v("Continue to login ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-scroll-x-transition",
        [
          !_vm.registered
            ? _c(
                "v-card",
                { attrs: { rounded: "lg" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("psi-form-section", {
                        ref: "form",
                        attrs: {
                          value: _vm.user,
                          name: "student-data",
                          fields: _vm.fields,
                          color: "accent"
                        },
                        on: { "update:password": _vm.updatePassword }
                      }),
                      _vm.error
                        ? _c(
                            "v-alert",
                            { attrs: { color: "error", icon: "mdi-alert" } },
                            [_vm._v(" " + _vm._s(_vm.error) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "v-card-actions",
                        { staticClass: "justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.dataLoading
                              },
                              on: { click: _vm.register }
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("mdi-account-plus")
                              ]),
                              _vm._v("Register")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }